import React, { useState } from "react";
import { Divider } from "@material-ui/core";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";

import PdfDialog from "../../../Shared/pdfDialog";

const metricAnswer = (faq, modalOpener) => {
  return (
    <div>
      {/* {faq?.answer} */}
      i2B Assistant uses KPIs and metrics from Cross-Functional Analytics & Drill Down dashboard of i2B. For current release i2B Assistant is trained only on the data from <a className="link" onClick={() => modalOpener("metric")}>Cross Functional Analytics.</a>
    </div>
  )
}

const sampleQuestionAnswer = (faq, modalOpener) => {
  return (
    <div>
      {/* {faq?.answer} */}
      Here is a <a className="link" onClick={() => modalOpener("question")}>list of questions</a> it can address. Use these as a guide, template or reference.
    </div>
  )
}

const FaqAccordian = ({ key, faq }) => {
  const [type, setType] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleOpenDialog = () => {
    setOpenModal(true);
  };

  const handleCloseDialog = () => {
    setOpenModal(false);
  };

  const modalOpener = (type) => {
    if (type === "metric") {
      setType("Metric list");
      handleOpenDialog();
    } else if (type === "question") {
      setType("Sample questions");
      handleOpenDialog();
    }
  }

  return (
    <div
      className="fsm-sidebar-content-faq-root"
      style={{ background: isExpanded ? "#460b73" : "" }}
    >
      <div
        onClick={() => {
          if (isExpanded) {
            setIsExpanded(false);
          } else {
            setIsExpanded(true);
          }
        }}
        className="fsm-sidebar-content-faq-ques-root"
      >
        <div className="fsm-sidebar-content-faq-ques">{faq?.query}</div>
        <div className="fsm-sidebar-content-faq-icon">
          {isExpanded ? (
            <ExpandLessIcon
              fontSize="medium"
              onClick={() => setIsExpanded(false)}
              style={{ fill: "#E4DDFC", cursor: "pointer" }}
            />
          ) : (
            <ExpandMoreIcon
              fontSize="medium"
              onClick={() => setIsExpanded(true)}
              style={{ fill: "#E4DDFC", cursor: "pointer" }}
            />
          )}
        </div>
      </div>
      {isExpanded ? (
        // <div className="fsm-sidebar-content-faq-ans">{faq?.answer}</div>
        <div className="fsm-sidebar-content-faq-ans">
          {faq?.type === "normal" ? faq?.answer : faq?.type === "metric" ?
          metricAnswer(faq, modalOpener): sampleQuestionAnswer(faq, modalOpener)}
        </div>
      ) : null}
      <Divider color="#C2B4E0" style={{ backgroundColor: "#C2B4E0", height: "0.5px" }} />
      {openModal && <PdfDialog type={type} openModal={openModal} handleClose={handleCloseDialog} />}
    </div>
  );
};

export default FaqAccordian;
